import { computed, reactive, type Ref, ref } from 'vue'
import type { Field, FieldSchema, Validator } from '../types'

interface ExtendedValidator<T> extends Required<Validator<T>> {
  isReady: boolean
  isValid: boolean
  canDisplayError: boolean
}

export function useField<T>(schema: FieldSchema<T>): Field<T> {
  function extendValidator(validator: Validator<T>) {
    return {
      rule: validator.rule,
      errorMessage: validator.errorMessage ?? '',
      trigger: validator.trigger ?? 'input',
      triggerErrorDisplay: validator.triggerErrorDisplay ?? 'blur',
      isReady: false,
      isValid: false,
      canDisplayError: false,
    }
  }

  const manuallySetError = ref<string>()
  const isManuallySetErrorClearedOnInput = ref(true)

  function setError(
    error: string,
    { clearOnInput = true }: { clearOnInput?: boolean } = {},
  ) {
    manuallySetError.value = error
    isManuallySetErrorClearedOnInput.value = clearOnInput
  }

  function clearError() {
    manuallySetError.value = undefined
    isManuallySetErrorClearedOnInput.value = true
  }

  const currentValue = ref(schema.initialValue) as Ref<T>
  const isFocused = ref(false)
  const validators = ref<ExtendedValidator<T>[]>(
    (schema.validators ?? []).map(extendValidator),
  )

  function reset() {
    currentValue.value = schema.initialValue
    isFocused.value = false
    validators.value = (schema.validators ?? []).map(extendValidator)
  }

  function handleFocus() {
    isFocused.value = true
  }

  function runValidator(validator: ExtendedValidator<T>) {
    const result = validator.rule(currentValue.value)
    if (typeof result === 'boolean') {
      validator.isValid = result
      validator.isReady = true
    } else {
      result.then((isValid) => {
        validator.isValid = isValid
        validator.isReady = true
      })
    }
  }

  async function handleBlur() {
    isFocused.value = false

    validators.value.forEach((validator) => {
      if (validator.triggerErrorDisplay === 'blur') {
        validator.canDisplayError = true
      }

      if (validator.trigger === 'blur') {
        runValidator(validator)
      }
    })
  }

  function handleInput(newValue: T) {
    currentValue.value = newValue
    if (isManuallySetErrorClearedOnInput.value) clearError()

    validators.value.forEach((validator) => {
      validator.isReady = false

      if (validator.triggerErrorDisplay === 'blur') {
        validator.canDisplayError = false
      } else if (validator.triggerErrorDisplay === 'input') {
        validator.canDisplayError = true
      }

      if (validator.trigger === 'input') {
        runValidator(validator)
      }
    })
  }

  const isValid = computed(() => {
    if (manuallySetError.value) return false
    return validators.value.every(
      (validator) => validator.isReady && validator.isValid,
    )
  })

  const isReadyValidation = computed(() => {
    if (manuallySetError.value) return true
    const someValidationFailed = validators.value.some(
      (validator) => !validator.isValid && validator.isReady,
    )
    const everyValidationIsReady = validators.value.every(
      (validator) => validator.isReady,
    )
    return someValidationFailed || everyValidationIsReady
  })

  const isForcefullyValidated = ref(false)

  const error = computed(() => {
    if (
      !isForcefullyValidated.value &&
      currentValue.value === schema.initialValue
    )
      return undefined

    if (manuallySetError.value) return manuallySetError.value
    const firstValidatorWithError = validators.value.find(
      (validator) =>
        validator.canDisplayError && !validator.isValid && validator.isReady,
    )
    return firstValidatorWithError?.errorMessage
  })

  const inputBindings = computed(() => ({
    value: currentValue.value,
    onInput: (event: Event) => {
      const target = event.target as HTMLInputElement
      handleInput(target.value as T)
    },
    onFocus: handleFocus,
    onBlur: handleBlur,
  }))

  const componentBindings = computed(() => ({
    modelValue: currentValue.value,
    error: Boolean(error.value),
    errorMessage: error.value,
    'onUpdate:modelValue': handleInput,
    onFocus: handleFocus,
    onBlur: handleBlur,
  }))

  function validate() {
    isForcefullyValidated.value = true
    validators.value.forEach((validator) => {
      validator.isReady = false
      validator.canDisplayError = true
      runValidator(validator)
    })
  }

  if (schema.immediate) {
    validate()
  }

  return reactive({
    value: currentValue,
    isValid,
    isReadyValidation,
    error,
    isFocused,
    inputBindings,
    componentBindings,
    setError,
    clearError,
    reset,
    validate,
  })
}
